import { isIOS, isSafari } from 'react-device-detect';
import * as Sentry from '@sentry/nextjs';

import { PreviewClip } from '@app/api/resources/PreviewClips';
import { TextTrack, TextTrackUrl } from '@app/api/resources/Reel';

import { isErrorInstance } from '@app/services/utils';

export type PlayerControlInterface = {
  doLoad: (options: any) => void;
  doPlay: () => void;
  doPause: () => void;
  isPaused: () => boolean;
  isEnded: () => boolean;
  doTogglePlay: () => void;
  isFullscreenEnabled: () => boolean;
  isFullscreenSupported: () => boolean;
  doExitFullscreen: () => void;
  doEnterFullscreen: () => void;
  doToggleFullscreen: () => void;
  getCurrentTime: () => number;
  setCurrentTime: (currentTime: number) => void;
  getVolume: () => void;
  setVolume: (volume: number) => void;
  setMuted: (muted: boolean) => void;
  isMuted: () => boolean;
  getBuffered: () => void;
  getDuration: () => number;
  doDispose: () => void;
  on?: () => void;
  off?: () => void;
  setOnProgress: (onProgress: boolean) => void;
  setOnTimeUpdate: (onTimeUpdate: () => void) => void;
  clearOnProgress: (onProgress: () => void) => void;
  clearOnTimeUpdate: (onTimeUpdate: () => void) => void;
  setOnPlay: (onPlay: () => void) => void;
  clearOnPlay: (onPlay: () => void) => void;
  setOnPause: (onPause: () => void) => void;
  clearOnPause: (onPause: () => void) => void;
  setOnSeeked: (onSeek: () => void) => void;
  clearOnSeeked: (onSeek: () => void) => void;
  setTextTrack: (textTrack: TextTrack | TextTrackUrl) => void;
  setAudioTrack: (audioTrack: any) => void;
  getPlayerContainer: () => Element | DocumentFragment;
  getPlugin: (pluginId: string) => void;
};

export const defaultPlayerControlInterface: PlayerControlInterface = {
  doLoad: () => {},
  doPlay: () => {},
  doPause: () => {},
  isPaused: () => null,
  isEnded: () => null,
  doTogglePlay: () => {},
  isFullscreenEnabled: () => null,
  isFullscreenSupported: () => null,
  doExitFullscreen: () => {},
  doEnterFullscreen: () => {},
  doToggleFullscreen: () => {},
  getCurrentTime: () => 0,
  setCurrentTime: () => {},
  getVolume: () => {},
  setVolume: () => {},
  setMuted: () => {},
  isMuted: () => null,
  getBuffered: () => {},
  getDuration: () => 100,
  doDispose: () => {},
  setOnProgress: () => {},
  clearOnProgress: () => {},
  setOnTimeUpdate: () => {},
  clearOnTimeUpdate: () => {},
  setOnPlay: () => {},
  clearOnPlay: () => {},
  setOnPause: () => {},
  clearOnPause: () => {},
  setOnSeeked: () => {},
  clearOnSeeked: () => {},
  setTextTrack: () => {},
  setAudioTrack: () => {},
  getPlayerContainer: () => document.body,
  getPlugin: () => {},
};

const ifPlayer = player => player;
const ifPlayerIsVideoNode = player => player?.nodeName === 'VIDEO';

const exitFullscreen = () => {
  if (!isFullscreen()) {
    return;
  }
  if (document?.exitFullscreen) {
    document.exitFullscreen();
  } else if (document?.mozExitFullScreen) {
    document.mozExitFullScreen();
  } else if (document?.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  }
};

const enterFullscreen = playerContainer => {
  if (playerContainer?.requestFullScreen) {
    playerContainer.requestFullScreen();
  } else if (playerContainer?.mozRequestFullScreen) {
    playerContainer.mozRequestFullScreen();
  } else if (playerContainer?.webkitRequestFullScreen) {
    playerContainer.webkitRequestFullScreen();
  }
};

const isFullscreen = () => {
  if (document?.fullscreenElement) {
    return document.fullscreenElement !== null;
  }
  if (document?.mozFullScreenElement) {
    return document?.mozFullScreenElement !== null;
  }
  if (document?.webkitFullscreenElement) {
    return document.webkitFullscreenElement !== null;
  }
  return false;
};

const isFullscreenSupportedByBrowser = () => {
  return document?.fullscreenEnabled || document?.webkitFullscreenEnabled;
};

export const getControlInterfaceForPrestoPlayer = (
  player,
  playerContainer?,
  { allowFullscreen = true }: { allowFullscreen?: boolean } = {},
): PlayerControlInterface => ({
  doLoad: options => (ifPlayer(player) ? player.load(options) : false),
  doPlay: () => (ifPlayer(player) ? player.play() : false),
  doPause: () => (ifPlayer(player) ? player.pause() : false),
  doTogglePlay: async () => {
    try {
      if (player) {
        if (player.isPaused()) {
          await player.play();
        } else {
          await player.pause();
        }
      }
    } catch (e) {
      // Ignoring error when browsers prevent play from occurring.
    }
  },
  isPaused: () => (ifPlayer(player) ? player.isPaused() : false),
  isEnded: () => (ifPlayer(player) ? player.isEnded() : false),
  isFullscreenEnabled: () => isFullscreen(),
  isFullscreenSupported: () => isFullscreenSupportedByBrowser(),
  doExitFullscreen: () => {
    exitFullscreen();
  },
  doEnterFullscreen: () => {
    if (allowFullscreen && playerContainer?.current) {
      enterFullscreen(playerContainer.current);
    }
  },
  doToggleFullscreen: () => {
    if (allowFullscreen) {
      if (isFullscreen()) {
        exitFullscreen();
      } else {
        if (playerContainer?.current) {
          enterFullscreen(playerContainer.current);
        }
      }
    }
  },
  getCurrentTime: () => (ifPlayer(player) ? player.getPosition() : 0),
  setCurrentTime: currentTime =>
    ifPlayer(player) ? player.seek(currentTime) : false,
  getVolume: () => (ifPlayer(player) ? player.getVolume() : 1),
  setVolume: volume => (ifPlayer(player) ? player.setVolume(volume) : false),
  setMuted: muted => (ifPlayer(player) ? player.setMuted(muted) : false),
  isMuted: () => (ifPlayer(player) ? player.isMuted() : false),
  getBuffered: () => (ifPlayer(player) ? player.getBufferInfo() : false),
  getDuration: () => (ifPlayer(player) ? player.getDuration() : 100),
  doDispose: () => (ifPlayer(player) ? player.destroy() : false),
  setOnProgress: onProgress =>
    ifPlayer(player) ? player.on('progress', onProgress) : false,
  setOnTimeUpdate: onTimeUpdate =>
    ifPlayer(player) ? player.on('timeupdate', onTimeUpdate) : false,
  clearOnProgress: onProgress =>
    ifPlayer(player) ? player.off('progress', onProgress) : false,
  clearOnTimeUpdate: onTimeUpdate =>
    ifPlayer(player) ? player.off('timeupdate', onTimeUpdate) : false,
  setOnPlay: onPlay => (ifPlayer(player) ? player.on('play', onPlay) : false),
  clearOnPlay: onPlay =>
    ifPlayer(player) ? player.off('play', onPlay) : false,
  setOnPause: onPause =>
    ifPlayer(player) ? player.on('pause', onPause) : false,
  clearOnPause: onPause =>
    ifPlayer(player) ? player.off('pause', onPause) : false,
  setOnSeeked: onSeek =>
    ifPlayer(player) ? player.on('seeked', onSeek) : false,
  clearOnSeeked: onSeek =>
    ifPlayer(player) ? player.off('seeked', onSeek) : false,
  setTextTrack: textTrack => {
    if (player) {
      const trackManager = player.getTrackManager();
      const matchingTextTrack =
        trackManager.findTextTrack({
          label: textTrack?.id,
        }) || null;

      trackManager.setTextTrack(matchingTextTrack);
    }
  },
  setAudioTrack: audioTrack => {
    if (player) {
      const trackManager = player.getTrackManager();
      const matchingAudioTrack =
        trackManager.findAudioTrack({
          label: audioTrack?.name,
        }) || null;
      trackManager.setAudioTrack(matchingAudioTrack);
    }
  },
  getPlayerContainer: () => {
    if (playerContainer?.current) {
      return playerContainer?.current;
    }
    return null;
  },
  getPlugin: pluginId => {
    if (player) {
      return player.getPlugin(pluginId);
    }
    return null;
  },
});

export const getControlInterfaceForHtmlVideo = (player, playerContainer) => ({
  doLoad: options =>
    ifPlayerIsVideoNode(player) ? player.load(options) : false,
  doPlay: () => (ifPlayerIsVideoNode(player) ? player.play() : false),
  doPause: () => (ifPlayerIsVideoNode(player) ? player.pause() : false),
  doTogglePlay: async () => {
    try {
      if (ifPlayerIsVideoNode(player)) {
        if (player?.paused) {
          await player.play();
        } else {
          await player.pause();
        }
      }
    } catch (e) {
      // Ignoring error when browsers prevent play from occurring.
    }
  },
  isPaused: () => {
    if (ifPlayerIsVideoNode(player)) {
      return player?.paused;
    }
    return null;
  },
  isEnded: () => {
    if (ifPlayerIsVideoNode(player)) {
      return player?.ended;
    }
    return null;
  },
  isFullscreenSupported: () => {
    if (ifPlayerIsVideoNode(player)) {
      return isFullscreenSupportedByBrowser();
    }
    return null;
  },
  isFullscreenEnabled: () => {
    if (ifPlayerIsVideoNode(player)) {
      return isFullscreen();
    }
    return null;
  },
  doExitFullscreen: () => {
    if (ifPlayerIsVideoNode(player)) {
      exitFullscreen();
    }
  },
  doEnterFullscreen: () => {
    if (ifPlayerIsVideoNode(player)) {
      enterFullscreen(playerContainer);
    }
  },
  doToggleFullscreen: () => {
    if (isFullscreen()) {
      exitFullscreen();
    } else {
      enterFullscreen(playerContainer);
    }
  },
  getCurrentTime: () => player?.currentTime || 0,
  setCurrentTime: currentTime => {
    if (ifPlayerIsVideoNode(player)) {
      // eslint-disable-next-line no-param-reassign
      player.currentTime = currentTime;
    }
  },
  getVolume: () => {
    if (ifPlayerIsVideoNode(player)) {
      return player?.volume;
    }
    return 1;
  },
  setVolume: volume => {
    if (ifPlayerIsVideoNode(player)) {
      // eslint-disable-next-line no-param-reassign
      player.volume = volume;
    }
  },
  setMuted: muted => {
    if (ifPlayerIsVideoNode(player)) {
      // eslint-disable-next-line no-param-reassign
      player.muted = muted;
    }
  },
  isMuted: () => {
    if (ifPlayerIsVideoNode(player)) {
      return player?.muted;
    }
    return false;
  },
  getBuffered: () => {
    if (ifPlayerIsVideoNode(player)) {
      return player?.buffered;
    }
    return {};
  },
  getDuration: () => {
    if (ifPlayerIsVideoNode(player)) {
      return player?.duration;
    }
    return 0;
  },
  doDispose: () => null,
  setOnProgress: onProgress => {
    if (ifPlayerIsVideoNode(player)) {
      // eslint-disable-next-line no-param-reassign
      player.onprogress = onProgress;
    }
  },
  setOnTimeUpdate: onTimeUpdate => {
    if (ifPlayerIsVideoNode(player)) {
      // eslint-disable-next-line no-param-reassign
      player.ontimeupdate = onTimeUpdate;
    }
  },
  clearOnProgress: () => null,
  clearOnTimeUpdate: () => null,
  setTextTrack: () => {},
  setAudioTrack: () => {},
  getPlayerContainer: () => {},
  setOnPlay: () => {},
  clearOnPlay: () => {},
  setOnPause: () => {},
  clearOnPause: () => {},
  setOnSeeked: () => {},
  clearOnSeeked: () => {},
});

export const getErrorSeverity = error =>
  error?.detail?.severity || error?.severity || 'UNKNOWN SEVERITY';

export const getErrorCode = error => {
  // https://players.castlabs.com/presto/6.1.8/#/docs?q=clpp.Error
  const prestoPlayerErrorCode = error?.detail?.code || error?.code || '0000';
  // https://developer.mozilla.org/en-US/docs/Web/API/MediaError
  const mediaErrorCode = error?.detail?.data?.code || error?.data?.code || '0';
  return `${prestoPlayerErrorCode} - ${mediaErrorCode}`;
};

export const clientSupportsHLS = () => isIOS || isSafari;

const getMessageFromError = error => {
  if (typeof error?.detail?.message === 'string') {
    return error.detail.message.substr(0, 100);
  }
  if (typeof error?.toString() === 'string') {
    return error.toString().substr(0, 100);
  }
  return '';
};

type ErrorInfo = {
  component: string;
  prestoErrorCode?: string;
  prestoErrorStatus?: string;
  prestoDrmTodayErrorCode?: string;
  prestoDrmTodayErrorMessage?: string;
  prestoErrorName?: string;
  prestoErrorMessage?: string;
};

export const isErrorCdmRevocation = error => {
  const DRM_TODAY_DENIED_ERROR = 403;
  const DRM_REVOKED_ERROR_CODES = ['40001', '40002'];

  let isDrmTodayDeniedError = false;
  let isDrmRevocationError = false;

  if (typeof error?.detail?.data?.status === 'number') {
    isDrmTodayDeniedError = error.detail.data.status === DRM_TODAY_DENIED_ERROR;
  }

  if (typeof error?.detail?.data?.drmTodayErrorCode === 'string') {
    isDrmRevocationError = DRM_REVOKED_ERROR_CODES.includes(
      error.detail.data.drmTodayErrorCode,
    );
  }

  return isDrmTodayDeniedError && isDrmRevocationError;
};

export const capturePrestoPlayerError = (error, extraErrorTags = {}) => {
  const captureErrorContext = scope => {
    const errorInfo: ErrorInfo = {
      component: 'PrestoPlayer',
    };
    if (error?.detail?.code) {
      errorInfo.prestoErrorCode = error?.detail?.code;
    }
    if (error?.detail?.name) {
      errorInfo.prestoErrorName = error?.detail?.name;
    }
    if (typeof error?.detail?.message === 'string') {
      errorInfo.prestoErrorMessage = getMessageFromError(error);
    }
    if (typeof error?.detail?.data?.status === 'number') {
      errorInfo.prestoErrorStatus = error.detail.data.status;
    }
    if (typeof error?.detail?.data?.drmTodayErrorCode === 'string') {
      errorInfo.prestoDrmTodayErrorCode = error.detail.data.drmTodayErrorCode;
    }
    if (typeof error?.detail?.data?.drmTodayErrorMessage === 'string') {
      errorInfo.prestoDrmTodayErrorMessage =
        error.detail.data.drmTodayErrorMessage;
    }
    Object.keys(errorInfo).forEach(key => {
      scope.setTag(key, errorInfo[key]);
    });
    Object.keys(extraErrorTags).forEach(key => {
      scope.setTag(key, errorInfo[key]);
    });

    return scope;
  };
  if (isErrorInstance(error)) {
    Sentry.captureException(error, captureErrorContext);
  } else {
    Sentry.captureMessage(getMessageFromError(error), captureErrorContext);
  }
};

export const shouldPreventEventFromFiring = e =>
  e?.target?.tagName?.toUpperCase() === 'INPUT' ||
  e?.target?.tagName?.toUpperCase() === 'TEXTAREA';

export const reportTrackSelectorError = (
  error,
  newTrack,
  trackSelectorType = 'multi-reel-selector',
) => {
  Sentry.captureException(
    new Error(`Failed to change track on ${trackSelectorType}`),
    {
      tags: { error_type: trackSelectorType },
      extra: {
        status: error?.status,
        response: error?.data,
        selectedTrack: newTrack,
      },
    },
  );
};

export const getClipSrcUrls = (previewClip: PreviewClip) => {
  if (clientSupportsHLS()) {
    return previewClip.urls.map(url => ({
      url: url.src,
      type: url.content_type,
    }));
  }

  return [
    {
      type: 'application/dash+xml',
      url: previewClip.urls.find(
        url => url.content_type === 'application/dash+xml',
      )?.src,
    },
  ];
};

export type SkipDirection = 'backward' | 'forward';
